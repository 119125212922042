import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "reset-password",
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/reset-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "dashboards.user",
    path: `${APP_PREFIX_PATH}/dashboards/user`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/user/index")
    ),
  },

  {
    key: "dashboards.query",
    path: `${APP_PREFIX_PATH}/dashboards/query`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/query/index")
    ),
  },
  {
    key: "dashboards.refrences",
    path: `${APP_PREFIX_PATH}/dashboards/refrences`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/references/index")
    ),
  },

  
  {
    key: "dashboards.testimonial",
    path: `${APP_PREFIX_PATH}/dashboards/testimonial`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/testimonial/index")
    ),
  },
  {
    key: "dashboards.careers",
    path: `${APP_PREFIX_PATH}/dashboards/careers`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/careers/index")
    ),
  },

  {
    key: "dashboards.resume",
    path: `${APP_PREFIX_PATH}/dashboards/resume`,
    component: React.lazy(() => import("views/app-views/dashboards/resume")),
  },

  {
    key: "dashboards.setting",
    path: `${APP_PREFIX_PATH}/dashboards/setting`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/side_setting/index")
    ),
  },
];
